import { all, call, delay, put, select, takeEvery } from "redux-saga/effects";
import { gql } from "graphql-tag";
import { apolloClient } from "../../apollo-client";
import {
  ApplicablePaymentOptionsResponseUnion,
  MutationMakeCardPaymentArgs,
  QueryCabinPaymentOptionsArgs,
} from "@generated/types";
import { PaymentDataStatus } from "../../components/Utils";
import {
  addCustomerToBooking,
  createCardPayment,
  createCardPaymentFail,
  createCardPaymentSuccess,
  resetMandatoryExtraInteractions,
} from "../slices";
import {
  bookingPersistedStateSelector,
  bookingSummarySelector,
  loginSelector,
} from "../selectors";
import { loadGraphQLQuery, loadGraphQLMutation } from "../../utils";
import {
  getPaymentOptionsAction,
  getPaymentOptionsFail,
  getPaymentOptionsSuccess,
} from "../slices/payment-options.slice";
import { postSSTBookingTransaction } from "src/utils/postsst";
import { captureException } from "@sentry/nextjs";

function* createCardPaymentSaga(action: ReturnType<typeof createCardPayment>) {
  const { bookingId } = yield select(bookingPersistedStateSelector);
  const { data: loginData } = yield select(loginSelector);
  const customerId = loginData?.customer?.id;

  const dynamicCreateCardPayment = yield call(
    loadGraphQLMutation,
    "makeCardPayment",
  );
  const createPaymentCall = () =>
    apolloClient.mutate<
      { makeCardPayment: PaymentResponse },
      MutationMakeCardPaymentArgs
    >({
      mutation: gql`
        ${dynamicCreateCardPayment}
      `,
      variables: action.payload.paymentInputRequest,
      fetchPolicy: "no-cache",
    });

  try {
    const response = yield call(createPaymentCall);
    const { makeCardPayment } = response.data!;

    if (
      makeCardPayment?.success &&
      (makeCardPayment?.status === PaymentDataStatus.OK ||
        makeCardPayment?.status === PaymentDataStatus.THREE_D_AUTH ||
        makeCardPayment?.status === PaymentDataStatus.GVOK ||
        makeCardPayment?.status === PaymentDataStatus.SCOK ||
        makeCardPayment?.status === PaymentDataStatus.GVSCOK)
    ) {
      yield put(
        createCardPaymentSuccess({
          paymentResponse: makeCardPayment,
          isBookingJourney: action.payload.isBookingJourney,
        }),
      );
      yield put(resetMandatoryExtraInteractions());
    } else {
      throw Error(
        makeCardPayment?.statusDetail ||
          makeCardPayment?.nonValidReason ||
          "Payment Failed",
      );
    }
  } catch (error: any) {
    // Retry logic if specific error occurs
    if (error.message === "BookingId does not have a lead booker") {
      const browserData =
        action.payload.paymentInputRequest?.paymentRequest?.cardPaymentRequest
          ?.browserData;
      captureException(`lead booker error info- ${browserData}`);
      // Dispatch addCustomerToBooking before retrying
      if (bookingId && customerId) {
        yield put(
          addCustomerToBooking({
            bookingId,
            customerId,
          }),
        );
        // Add a 1-second delay before retrying
        yield delay(1000);
      }
      try {
        const retryResponse = yield call(createPaymentCall);
        const { makeCardPayment } = retryResponse.data!;

        if (
          makeCardPayment?.success &&
          (makeCardPayment?.status === PaymentDataStatus.OK ||
            makeCardPayment?.status === PaymentDataStatus.THREE_D_AUTH ||
            makeCardPayment?.status === PaymentDataStatus.GVOK ||
            makeCardPayment?.status === PaymentDataStatus.SCOK ||
            makeCardPayment?.status === PaymentDataStatus.GVSCOK)
        ) {
          yield put(
            createCardPaymentSuccess({
              paymentResponse: makeCardPayment,
              isBookingJourney: action.payload.isBookingJourney,
            }),
          );
          yield put(resetMandatoryExtraInteractions());
          return; // Exit after successful retry
        } else {
          throw new Error(
            makeCardPayment?.statusDetail ||
              makeCardPayment?.nonValidReason ||
              "Payment Failed",
          );
        }
      } catch (retryError: any) {
        // Dispatch failure if retry also fails
        yield put(createCardPaymentFail(retryError.message));
        return; // Exit saga
      }
    }

    // Dispatch failure if error is unrelated or retry is not triggered
    yield put(createCardPaymentFail(error.message));
  }
}

function* createCardPaymentSuccessSaga(
  action: ReturnType<typeof createCardPaymentSuccess>,
) {
  const is3DAuth = action?.payload?.paymentResponse?.status === "3DAUTH";
  if (!is3DAuth && action.payload.isBookingJourney) {
    const { confirmation, selectedPaymentOption } = yield select(
      bookingSummarySelector,
    );
    postSSTBookingTransaction(confirmation);
  }
}

function* getApplicablePaymentOptionsSaga(
  action: ReturnType<typeof getPaymentOptionsAction>,
) {
  const dynamicGetApplicablePaymentOptions = yield call(
    loadGraphQLQuery,
    "cabinPaymentOptions",
  );
  const { data } = yield select(loginSelector);
  const getApplicablePaymentOptionsCall = () =>
    apolloClient.query<
      { paymentOptionsAndPrices: ApplicablePaymentOptionsResponseUnion },
      QueryCabinPaymentOptionsArgs
    >({
      query: gql`
        ${dynamicGetApplicablePaymentOptions}
      `,
      variables: action.payload,
      ...(data?.token
        ? {
            context: {
              headers: {
                authorization: `Bearer ${data.token}`,
              },
            },
          }
        : {}),
      fetchPolicy: "no-cache",
    });

  try {
    const getApplicablePaymentOptionsResponse = yield call(
      getApplicablePaymentOptionsCall,
    );
    const { cabinPaymentOptions } = getApplicablePaymentOptionsResponse.data!;

    switch (cabinPaymentOptions?.__typename) {
      case "ApplicablePaymentOptionsResponse":
        if (!!cabinPaymentOptions?.paymentOptionsAndPrices) {
          yield put(
            getPaymentOptionsSuccess(
              cabinPaymentOptions.paymentOptionsAndPrices,
            ),
          );
        }
        break;
      case "SystemError":
        yield put(getPaymentOptionsFail(cabinPaymentOptions?.message || ""));
      default:
        break;
    }
  } catch (error: any) {
    yield put(getPaymentOptionsFail(error?.message || ""));
  }
}

export default function* paymentSagas() {
  yield all([
    takeEvery("createPayment/createCardPayment", createCardPaymentSaga),
    takeEvery(
      "createPayment/createCardPaymentSuccess",
      createCardPaymentSuccessSaga,
    ),
    takeEvery(
      "paymentOptions/getPaymentOptionsAction",
      getApplicablePaymentOptionsSaga,
    ),
  ]);
}
