import React, { FC, PropsWithChildren } from "react";
import {
  CircularProgress,
  Container,
  Grid,
  Input,
  Link,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { FHBreadcrumb, FHBreadcrumbProps } from "../FHBreadcrumb";
import { FHNextImage, ImageLoader } from "../FHNextImage";

const useStyles = (reduceFooterSpacing?: boolean) =>
  makeStyles((theme) => ({
    root: {
      position: "relative",
      backgroundColor: theme.palette.primary.dark,
      "& .MuiTypography-root": {
        color: theme.palette.background.paper,
      },
      "& $error": {
        color: theme.palette.error.light,
      },
    },
    footerImg: {
      position: "absolute",
      zIndex: 1,
      left: "50%",
      top: 46,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    title: {
      fontWeight: 600,
      fontFamily: theme.typography.h2.fontFamily,
    },
    subtitle: {
      fontWeight: 500,
    },
    formContainer: {
      position: "relative",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3),
      marginBottom: reduceFooterSpacing ? theme.spacing(4) : theme.spacing(9),
      borderBottom: `2px solid rgba(255,255,255,0.2)`,
      zIndex: 2,

      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
      },
    },
    socials: {
      justifyContent: "flex-end",
      color: theme.palette.background.paper,

      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(3),
      },
    },
    bottomGutter: {
      paddingBottom: theme.spacing(2),
    },
    textField: {
      padding: theme.spacing(1, 2),
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.background.paper,
      marginRight: theme.spacing(2),

      "&::after, &::before": {
        display: "none",
      },
      "& input": {
        animationName: "none",
      },
    },
    subscribe: {
      minHeight: "52px",
      width: "100%",
      textTransform: "none",
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.background.paper,
      color: theme.palette.background.paper,
    },
    logoContainer: {
      borderBottom: `2px solid rgba(255,255,255,0.2)`,
      marginTop: reduceFooterSpacing ? theme.spacing(4) : theme.spacing(6),
    },
    logo: {
      marginTop: "20px",
      marginBottom: "20px",

      "& svg": {
        color: theme.palette.background.paper,
      },
    },
    copyright: {
      marginTop: "20px",
      marginBottom: "20px",
    },
    linksContainer: {
      color: theme.palette.background.paper,
    },
    footerImgBottom: {
      position: "absolute",
      zIndex: 1,
      right: 0,
      top: "50%",
      marginTop: -65,
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
      "& >span": {
        "& >span": {
          "& >img": {
            position: "absolute",
            inset: 0,
            boxSizing: "border-box",
            padding: 0,
            border: "none",
            margin: "auto",
            display: "block",
            width: 0,
            height: 0,
            minWidth: "100%",
            maxWidth: "100%",
            minHeight: "100%",
            maxHeight: "100%",
          },
        },
      },
    },
    linksGroup: {
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        "&::after": {
          content: '""',
          display: "block",
          borderBottom: "2px solid rgba(255,255,255,0.2)",
          marginTop: theme.spacing(2),
        },
      },

      "& a": {
        "&:hover": {
          textDecoration: "underline",
        },
      },

      "&:first-child": {
        [theme.breakpoints.up("md")]: {
          paddingRight: theme.spacing(8),
        },
      },
    },
    awardsTextContainer: {
      "& div": {
        "& p": {
          fontSize: "16px",
          color: theme.palette.background.paper,
          fontFamily: theme.typography.fontFamily,
          lineHeight: "24px",
          marginTop: "0.2px",
        },
      },
    },
    awardsImageContainer: {
      position: "relative",
      marginTop: reduceFooterSpacing ? theme.spacing(3.2) : theme.spacing(6),
      marginBottom: reduceFooterSpacing ? theme.spacing(3.2) : theme.spacing(4),
      height: 130,
    },
    awardsLinkContainer: {
      "& svg": {
        verticalAlign: "top",
      },
    },
    error: {
      marginTop: theme.spacing(1.5),
      color: theme.palette.error.light,
    },
    footerContainer: {
      marginBottom: 0,
    },
  }))();

export type FooterLink = {
  label: string;
  url: string;
};

export type FooterProps = {
  AwardsImage?: FC;
  AwardsText?: FC;
  bgImgUrl?: string;
  footerCtaLabel?: string;
  footerCtaUrl?: string;
  email: string;
  firstName: string;
  links?: FooterLink[][];
  Logo?: FC;
  SocialIcons?: FC;
  SubscribeButton?: FC<{ className: string }>;
  subtitle?: string;
  title?: string;
  newsletterComplete?: boolean;
  newsletterLoading?: boolean;
  errors?: NewsletterErrors[];
  signUpConfirmationMessage?: string;
  setEmail?(email: string): void;
  setFirstName?(name: string): void;
  breadcrumbProps?: FHBreadcrumbProps;
  imageLoader?: ImageLoader;
  reduceFooterSpacing?: boolean;
  expChangeHomeIcon?: boolean;
};

export type NewsletterErrors = {
  errorField: string;
  errorMessage: string;
};

enum ErrorsEnum {
  Forenames = "Forenames",
  Email = "Email",
}

export const Footer: FC<FooterProps> = ({
  AwardsImage,
  footerCtaLabel,
  footerCtaUrl,
  AwardsText,
  bgImgUrl,
  email,
  firstName,
  links = [],
  Logo,
  SocialIcons,
  SubscribeButton,
  subtitle,
  title,
  newsletterComplete = false,
  newsletterLoading,
  errors,
  signUpConfirmationMessage,
  setEmail,
  setFirstName,
  breadcrumbProps,
  imageLoader,
  reduceFooterSpacing,
  expChangeHomeIcon = false,
}: PropsWithChildren<FooterProps>) => {
  const classes = useStyles(reduceFooterSpacing);
  const handleErrorMessage = (errorId: ErrorsEnum) =>
    errors?.map(
      (error, idx) =>
        error.errorField === errorId && (
          <Grid key={`error-field-${idx}`} item xs={12}>
            <Typography variant="body1" className={classes.error}>
              {error.errorMessage}
            </Typography>
          </Grid>
        ),
    );

  return (
    <footer className={classes.root} data-testid="root" id="signup_footer">
      {bgImgUrl && (
        <div className={classes.footerImg}>
          <FHNextImage
            src={bgImgUrl}
            alt="Forest Holidays"
            width="223"
            height="130"
            loading="lazy"
            loader={imageLoader}
          />
        </div>
      )}
      <Container className={classes.footerContainer}>
        {!!breadcrumbProps && breadcrumbProps.breadcrumbs.length > 0 && (
          <FHBreadcrumb
            breadcrumbs={breadcrumbProps.breadcrumbs}
            maxItemsOnMobile={breadcrumbProps.maxItemsOnMobile}
            theme={breadcrumbProps.theme}
            separator={breadcrumbProps.separator}
            expChangeHomeIcon={expChangeHomeIcon}
          />
        )}
        <Grid container className={classes.formContainer}>
          <Grid item xs={12}>
            {title && !newsletterComplete && (
              <Typography
                variant="h2"
                className={classes.title}
                data-testid="title"
              >
                {title}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} className={classes.bottomGutter}>
            {subtitle && !newsletterComplete && (
              <Typography
                variant="h4"
                className={classes.subtitle}
                data-testid="subtitle"
              >
                {subtitle}
              </Typography>
            )}
          </Grid>
          <Grid item container xs={12}>
            {!newsletterComplete ? (
              <Grid item xs={12} md={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm>
                    <Input
                      id="firstName"
                      className={classes.textField}
                      placeholder={firstName}
                      onChange={(e) => {
                        setFirstName && setFirstName(e.target.value);
                      }}
                      fullWidth
                    />
                    {!newsletterComplete &&
                      handleErrorMessage(ErrorsEnum.Forenames)}
                  </Grid>
                  <Grid item sm xs={12}>
                    <Input
                      id="email"
                      className={classes.textField}
                      placeholder={email}
                      onChange={(e) => {
                        setEmail && setEmail(e.target.value);
                      }}
                      fullWidth
                    />
                    {!newsletterComplete &&
                      handleErrorMessage(ErrorsEnum.Email)}
                  </Grid>
                  <Grid item sm xs={12}>
                    {SubscribeButton && (
                      <SubscribeButton className={classes.subscribe} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : newsletterLoading ? (
              <CircularProgress />
            ) : (
              <Grid item xs={12} md={8}>
                <Typography variant="h3">
                  {signUpConfirmationMessage ? signUpConfirmationMessage : ""}
                </Typography>
              </Grid>
            )}
            <Grid
              item
              container
              className={classes.socials}
              wrap="nowrap"
              alignItems="center"
              xs={12}
              md={4}
            >
              {SocialIcons && <SocialIcons />}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <nav aria-label="Bottom Navigation">
              <Grid container spacing={2} className={classes.linksContainer}>
                {links?.map((group: FooterLink[], groupIndex: number) => (
                  <Grid
                    xs={12}
                    md={6}
                    item
                    key={`link-group-${groupIndex}`}
                    className={classes.linksGroup}
                  >
                    {groupIndex === 0 && bgImgUrl && (
                      <div className={classes.footerImgBottom}>
                        <FHNextImage
                          src={bgImgUrl}
                          alt="Forest Holidays"
                          width="223"
                          height="130"
                          loading="lazy"
                          loader={imageLoader}
                        />
                      </div>
                    )}
                    {group?.map((link: FooterLink, linkIndex: number) => (
                      <Link
                        href={link.url}
                        data-testid="links"
                        key={`link-${groupIndex}-${linkIndex}`}
                        underline="hover"
                      >
                        <Typography>{link.label}</Typography>
                      </Link>
                    ))}
                  </Grid>
                ))}
              </Grid>
            </nav>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.awardsTextContainer}>
              {AwardsText && <AwardsText />}
            </div>
            <div className={classes.awardsImageContainer}>
              {AwardsImage && <AwardsImage />}
            </div>
            {footerCtaLabel && footerCtaUrl && (
              <Grid container alignItems="flex-start" data-testid="awards-link">
                <div className={classes.awardsLinkContainer}>
                  <Link href={footerCtaUrl} underline="hover">
                    <Typography>
                      {footerCtaLabel} <ChevronRight />
                    </Typography>
                  </Link>
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          alignItems={"flex-start"}
          direction={"column"}
          justifyContent="center"
          className={classes.logoContainer}
        >
          <Grid item xs className={classes.logo}>
            {Logo && <Logo />}
          </Grid>
        </Grid>
        <Grid container alignItems="flex-end" justifyContent="flex-end">
          <Grid item>
            <Typography className={classes.copyright}>
              &copy; Forest Holidays {new Date().getFullYear()}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};
