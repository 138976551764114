import { useState, useEffect, Dispatch } from "react";
import moment from "moment";
import {
  ConfirmedBookingCabin,
  FutureBooking_S,
  Maybe,
  MutationRemoveGiftVoucherFromBookingArgs,
  RemoveGiftVoucherResponse,
  Scalars,
} from "@generated/types";
import { BookingJourneyStep, StepperTag } from "./interfaces/booking";
import { ExtrasStepperTag } from "./interfaces/extras";
import { GvJourneyStep, GvStepperTag } from "./interfaces/giftvouchers";
import {
  useBookingPersistedStateSelector,
  useGvSettingsSelector,
} from "./store/selectors";
import { StandardPageProps } from "./utils/common";
import { RedisDbs } from "./constants/RedisDb";
import { filterXSS } from "xss";
import { HttpAuthParams } from "./types/Content";
import { StorybookURLs } from "./constants/StorybookURLs";
import { BannerProps } from "./utils/common";
import { InterstitialBookingSearch, Guests } from "./interfaces/guests";
import { apolloClient } from "./apollo-client";
import removeVoucherMutation from "./graphql/bsl/gql-generated/dot-gql/mutations/removeGiftVoucherFromBooking.gql";
import { removeVouchersFromCurrentBooking } from "./store/slices";
import { Voucher } from "@components/SemanticTheme/VoucherForm";
import axios from "axios";
import { CabinInfo } from "@components/SemanticTheme/MyAccountDashboard/Booking";
import { getIsBookingHappeningNow } from "@components/Utils";

export type Nullable<T> = T | null;
export const isUrl = (url: string) => {
  try {
    return Boolean(new URL(url));
  } catch (error) {
    return false;
  }
};

export const createPathFromUrl = (url: string): string => {
  if (isUrl(url)) {
    const webUrl = new URL(url);
    return filterXSS(webUrl.pathname);
  }

  return url;
};

export function getBookingStepByUrl(
  fhBookingSteps: Nullable<BookingJourneyStep[]>,
) {
  const url = filterXSS(window?.location?.pathname) || "";
  if (!!url) {
    return (fhBookingSteps || []).find((step) => step?.url === url);
  }
  return undefined;
}

export const addTrailingSlashToUrl = (url: string) => {
  if (!!url) {
    return filterXSS(url?.endsWith("/") ? url : url.replace(/\/?$/, "/"));
  }
};

export function getExtrasStepByUrl(
  fhExtrasSteps: Nullable<BookingJourneyStep[]>,
) {
  const url = filterXSS(window?.location?.pathname) || "";
  if (!!url) {
    return (fhExtrasSteps || []).find((step) => step?.url === url);
  }
  return undefined;
}

export function getBookingStepByTag(
  tag: StepperTag,
  fhBookingSteps: Nullable<BookingJourneyStep[]>,
) {
  return (fhBookingSteps || []).find((step) => step?.tag === tag);
}

export function getBookingStepIndexByTag(
  tag: StepperTag,
  fhBookingSteps: Nullable<BookingJourneyStep[]>,
) {
  return (fhBookingSteps || []).findIndex((step) => step?.tag === tag);
}

export function getExtrasStepByTag(
  tag: ExtrasStepperTag,
  fhExtrasSteps: Nullable<BookingJourneyStep[]>,
) {
  return (fhExtrasSteps || []).find(
    (step) => step?.tag?.replace(" ", "") === tag.replace(" ", ""),
  );
}

export const isGuid = (guid: string) =>
  /^[0-9a-f]{8}-?[0-9a-f]{4}-?[1-5][0-9a-f]{3}-?[89ab][0-9a-f]{3}-?[0-9a-f]{12}$/i.test(
    guid?.replace(/-/g, "")?.toLowerCase(),
  );

export const defaultBookingWidths = {
  xs: 600,
  sm: 960,
  md: 600,
  lg: 600,
  xl: 900,
};

export const getBSLUrl = () => {
  return process.env.NEXT_PUBLIC_BSL_ENDPOINT.replace("/graphql/", "");
};

export function getGvStepByTag(
  tag: GvStepperTag,
  gvBookingSteps: Nullable<GvJourneyStep[]>,
) {
  return (gvBookingSteps || []).find((step) => step.tag === tag);
}

export const useGVRedirectionObjectByPath = (pagePath: string) => {
  const { gvBookingSteps } = useGvSettingsSelector();
  const activeStepIndex = gvBookingSteps
    ? gvBookingSteps.findIndex((p) => p.url == filterXSS(pagePath))
    : 0;
  const nextPageUrl = gvBookingSteps && gvBookingSteps[activeStepIndex + 1];
  const prevPageUrl =
    gvBookingSteps &&
    gvBookingSteps.length > 1 &&
    gvBookingSteps[activeStepIndex - 1];
  return {
    nextPageUrl: nextPageUrl,
    prevPageUrl: prevPageUrl,
    activeStepIndex: activeStepIndex,
    formId: "add-update-form",
  };
};

export function useQueryString() {
  if (typeof window !== "undefined") {
    const queryData = filterXSS(window?.location?.search);
    return new URLSearchParams(queryData);
  }
}

export const isValidDeposit = (depositAmount: number) =>
  depositAmount !== null && depositAmount >= 0;

export const getMinValue = (values: number[]) =>
  values.reduce(
    (value1, value2) => (value2 != null && value2 < value1 ? value2 : value1),
    Infinity,
  );

const getMomentDate = (
  date: Scalars["DateTime"]["input"]["output"],
): moment.Moment => {
  return moment(date).utc();
};

export const formatDate = (
  date: Scalars["DateTime"],
  includeYear?: boolean,
): string => {
  // example returned value: '22 Aug'
  // example returned value including year: '22 Aug 2022'

  const includeYearInFormat = includeYear ?? true;
  return getMomentDate(date).format(
    includeYearInFormat ? "D MMM YYYY" : "D MMM",
  );
};
export const scrollWindowToTop = () =>
  window.scrollTo({ top: 0, behavior: "smooth" });

export const assignFeefoReviewAPITag = (
  pageProperties: StandardPageProps,
  cmsData,
) => {
  Object.assign(pageProperties, {
    FeefoReviewAPITag: cmsData?.FeefoReviewAPITag,
    Title: cmsData?.Title,
  });
};

export const useSelectedCabin = (
  cabinReservations?: Maybe<ConfirmedBookingCabin>[] | null,
) => {
  const { confirmedCabinReservationId } = useBookingPersistedStateSelector();
  const [selectedCabin, setSelectedCabin] =
    useState<ConfirmedBookingCabin | null>();

  useEffect(() => {
    if (!!cabinReservations) {
      setSelectedCabin(
        cabinReservations.find(
          (cabin) => cabin?.cabinReservationId === confirmedCabinReservationId,
        ),
      );
    }
  }, [cabinReservations, confirmedCabinReservationId]);
  return selectedCabin;
};

export const formatDateRange = (
  startDate: Scalars["DateTime"],
  endDate: Scalars["DateTime"],
): string => {
  const start = getMomentDate(startDate);
  const end = getMomentDate(endDate);
  const sameMonth = start.isSame(end, "month");
  const sameYear = start.isSame(end, "year");

  // example returned value: 'Mon 22 - Fri 26 Aug, 2022'
  // example returned value for different month & year: 'Fri 30 Dec 2022 - Mon 2 Jan, 2023'
  return `${start.format("ddd D")}${
    !sameMonth ? ` ${start.format("MMM")}` : ""
  }${!sameYear ? ` ${start.format("YYYY")}` : ""} - ${end.format(
    "ddd D MMM YYYY",
  )}`;
};

export const calculateNumberOfNights = (
  startDate: Scalars["DateTime"],
  endDate: Scalars["DateTime"],
): number => {
  return getMomentDate(endDate).diff(getMomentDate(startDate), "days");
};

export const getRedisDbNumber = () => {
  if (process.env.STAGE === "Dev") return RedisDbs.Development;
  if (process.env.STAGE === "QA") return RedisDbs.QA;
  if (process.env.STAGE === "Dev2") return RedisDbs.Development2;
  if (process.env.STAGE === "QA2") return RedisDbs.QA2;
  if (process.env.STAGE === "Production") return RedisDbs.Production;

  return RedisDbs.Development;
};

export const getRedisKey = (keyType: string, routeName: string) =>
  `${process.env.STAGE}__${keyType}${String(routeName).replaceAll("/", "__")}`;

export const getBasicAuthUserNameAndPassword = () => {
  let auth: HttpAuthParams | {} = {};
  if (process.env.ENABLE_BASIC_AUTHENTICATION == "true") {
    auth = {
      auth: {
        username: process.env.BASIC_AUTH_USER_NAME,
        password: process.env.BASIC_AUTH_USER_PASSWORD,
      },
    };
  }

  return auth;
};

export const isSSRFetchRequiredFromBSL = (routeName: string) => {
  const ssrFetchRequiredRoutesFromBSL = {
    "/cabins/": "/get-cabins",
    "/forestipedia/": "/get-blogs",
  };

  if (ssrFetchRequiredRoutesFromBSL[routeName])
    return ssrFetchRequiredRoutesFromBSL[routeName];

  return null;
};

//checks if a Trading Tab is expired or not
export const checkTradingTabExpiry = (props: BannerProps): boolean => {
  const tabVisibleFromDateTime = new Date(props?.tabVisibleFrom).getTime();
  const tabVisibleToDateTime = new Date(props?.tabVisibleTo).getTime();
  const currentDateTime = Date.now();

  const checkDateTime = (): boolean => {
    return (
      currentDateTime >= tabVisibleFromDateTime &&
      currentDateTime <= tabVisibleToDateTime
    );
  };

  const hasDates = props?.tabVisibleFrom && props?.tabVisibleTo;
  return (
    (hasDates && props?.tabEnabled && checkDateTime()) ||
    (!hasDates && props?.tabEnabled)
  );
};

export const isValidDate = (dateString: string): boolean =>
  !isNaN(Date.parse(dateString));

export const getStorybookBaseUrl = () => {
  if (process.env.STAGE === "Local") return StorybookURLs.Local;
  if (process.env.STAGE === "Dev") return StorybookURLs.Development;
  if (process.env.STAGE === "QA") return StorybookURLs.QA;
  return StorybookURLs.Local;
};

export const monthNameToNumber = (monthName: string): number => {
  const monthMoment = moment(monthName, "MMM");
  return monthMoment.month() + 1;
};

export const get400DaysAfterDateFromCurrentDate = () => {
  const fourHundredDayExpiry = new Date();
  fourHundredDayExpiry.setDate(fourHundredDayExpiry.getDate() + 400);
  return fourHundredDayExpiry;
};

const areGuestsEqual = (guests1: Guests, guests2: Guests): boolean => {
  // Check if all the properties in the Guests objects are the same
  return (
    guests1?.adults?.toString() === guests2?.adults?.toString() &&
    guests1?.children?.toString() === guests2?.children?.toString() &&
    guests1?.infants?.toString() === guests2?.infants?.toString() &&
    guests1?.pets?.toString() === guests2?.pets?.toString() &&
    guests1?.bedrooms?.toString() === guests2?.bedrooms?.toString()
  );
};

export const areBookingSearchObjectsEqual = (
  obj1: InterstitialBookingSearch,
  obj2: InterstitialBookingSearch,
): boolean => {
  // Check if all the properties are the same
  return (
    obj1?.startDate?.toString() === obj2?.startDate?.toString() &&
    obj1?.cabinId?.toString() === obj2?.cabinId?.toString() &&
    obj1?.duration?.toString() === obj2?.duration?.toString() &&
    areGuestsEqual(obj1?.guests, obj2?.guests) &&
    obj1?.price?.toString() === obj2?.price?.toString()
  );
};

export const removeAllGiftVouchersFromCurrentBooking = async (
  dispatch: Dispatch<any>, // Replace 'any' with YourRootState if you have a specific root state type
  vouchers: Voucher[],
) => {
  try {
    const bslResponse = await Promise.all(
      vouchers.map((voucherDetails) => {
        return apolloClient.mutate<
          { removeGiftVoucherFromBooking: RemoveGiftVoucherResponse },
          MutationRemoveGiftVoucherFromBookingArgs
        >({
          mutation: removeVoucherMutation,
          variables: { giftVoucherCode: voucherDetails.code },
          fetchPolicy: "no-cache",
        });
      }),
    );

    const bslErrors = bslResponse.find(
      (response) =>
        response.data?.removeGiftVoucherFromBooking.success === false,
    );

    if (!bslErrors) {
      // dispatch an action to remove all gvs from redux locally.
      dispatch(removeVouchersFromCurrentBooking());
    }
  } catch (e) {
    console.log("Error", e);
  }
};

export const getAbsoluteURL = (url: string) => {
  if (!!url) {
    const baseURL = `${process.env.NEXT_PUBLIC_BASE_URL}`.replace(/\/$/, "");
    const absoluteURL = url?.includes(baseURL) ? url : `${baseURL}${url}`;
    return absoluteURL;
  }
};

export const OnMobileDevice = (nextHeaders?: any) => {
  const userAgent = nextHeaders?.["user-agent"];

  return (
    userAgent?.includes("Mobile") ||
    userAgent?.includes("iPhone") ||
    userAgent?.includes("Android") ||
    userAgent?.includes("iPad")
  );
};

export const debounce = (func: Function, wait: number) => {
  let timeout: NodeJS.Timeout | null;
  return (...args: any[]) => {
    const later = () => {
      timeout = null;
      func(...args);
    };
    clearTimeout(timeout as NodeJS.Timeout);
    timeout = setTimeout(later, wait);
  };
};

// Function to update image attributes and add double black space
export const updateImagesAndAddSpace = (htmlString, newImageHtml) => {
  // Update width and height of all images to 150
  const updatedHtmlString = htmlString?.replace(/<img[^>]+>/g, (imgTag) => {
    return imgTag
      ?.replace(/width="\d+"/, 'width="150"')
      ?.replace(/height="\d+"/, 'height="150"');
  });

  // Add double black space between images
  // const spacedHtmlString = updatedHtmlString?.replace(
  //   /&nbsp;/g,
  //   "&nbsp;&nbsp;",
  // );

  // Find the position before the closing </p> tag
  const position = updatedHtmlString?.lastIndexOf("</p>");
  if (position === -1) {
    return updatedHtmlString; // If </p> tag is not found, return the original string
  }

  // Insert the new image HTML before the closing </p> tag
  const finalHtmlString =
    updatedHtmlString?.slice(0, position) +
    newImageHtml +
    updatedHtmlString?.slice(position);

  return finalHtmlString;
};

export const loadGraphQLQuery = async (queryName: string) => {
  const mod = await import(
    `../src/graphql/bsl/gql-generated/dot-gql/queries/${queryName}.gql`
  );
  return mod.default;
};

export const loadGraphQLMutation = async (mutationName: string) => {
  const mod = await import(
    `../src/graphql/bsl/gql-generated/dot-gql/mutations/${mutationName}.gql`
  );
  return mod.default;
};

export const loadCustomGraphQLQuery = async (queryName: string) => {
  const mod = await import(
    `../src/graphql/bsl/gql-custom/queries/${queryName}.gql`
  );
  return mod.default;
};

export const loadCustomGraphQLMutation = async (mutationName: string) => {
  const mod = await import(
    `../src/graphql/bsl/gql-custom/mutations/${mutationName}.gql`
  );
  return mod.default;
};

export const getCabinsForBookingComponent = (
  booking: FutureBooking_S,
): CabinInfo[] => {
  if (!!booking && !!booking?.cabins?.[0]) {
    let cabinsData: CabinInfo[] = [];
    booking.cabins.map((cabin) => {
      const cabinData: CabinInfo = {
        cabinReservationId: cabin.cabinReservationId,
        cabinType: cabin.cabinTypeName,
        numBeds: cabin.numberOfBedrooms,
        petFriendly: cabin.petFriendly,
        sleeps: cabin.sleeps,
        numDoubleBeds: cabin.doubleBeds,
        numTwinBeds: cabin.twinBeds,
      };
      cabinsData.push(cabinData);
    });
    return cabinsData;
  } else {
    return undefined;
  }
};

export const getDatesForBookingComponent = (
  booking: FutureBooking_S,
): string => {
  if (!!booking && !!booking.startDate && !!booking.endDate) {
    const { startDate, endDate } = booking;
    const dateRange = formatDateRange(startDate, endDate);
    return dateRange || "";
  }
  return "";
};

export const isActiveBooking = (booking: FutureBooking_S) => {
  if (!!booking && !!booking?.startDate && !!booking?.endDate) {
    return getIsBookingHappeningNow({
      startDate: booking.startDate,
      endDate: booking.endDate,
    });
  } else {
    return false;
  }
};
