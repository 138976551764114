var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"bbS5dOhYr5DVzu_bLpcTM"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

// const SENTRY_DSN_STRING =
//   process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV !== "development")
  Sentry.init({
    dsn: "https://cad685f151a14213a7490ac8015856e6@o1161108.ingest.sentry.io/4504672309018624",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: parseFloat(
      process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE
    ),
    environment: process.env.STAGE,
  });
